$app-color: #2A7AE2;
$app-color-inverted: #fff;

.app {
  margin-bottom: 4rem;

  &-notice {
    font-size: 0.8rem;
    opacity: 0.65;

    &__text {
      margin-bottom: 0.25rem;
    }
  }

  &-container {
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(black,0.25);

    padding: 1rem;

    display: grid;
    grid-template-columns: 1fr 64px;
    grid-gap: 0.5rem 1rem;
  }

  &-icon {
    order: 2;

    margin: 0;
  }

  &-header {
    order: 1;
    justify-items: center;

    line-height: 1rem;

    &__title { 
      font-size: 1.5rem; 
      margin: 0.7rem 0;
    }
    &__subtitle { 
      opacity: 0.5; 
      margin: 0;
    }
  }

  &-body { 
    order: 3;

    font-size: 0.9rem;

    grid-column-start: 1;
    grid-column-end: span 2;

    *:last-child { margin-bottom: 0; }
  }

  &-actions {
    display: none;

    &__button {
      font-size: 1rem;

      display: inline-block;

      padding: 0.3rem 0.5rem;
      margin-left: 0.5rem;
      border-radius: 3px;
      text-decoration: none;

      &:hover,&:visited,&:active {
        text-decoration: none;
      }

      &:first-child { margin-left: 0; }

      &--primary {
        color: $app-color-inverted;
        background-color: rgba($app-color,0.9);

        &:hover,&:visited,&:active { 
          color: $app-color-inverted;
          background-color: $app-color; 
        }
      }

      &--text {
        background-color: none;
        color: rgba($app-color,0.8);

        &:hover,&:visited,&:active { 
          color: $app-color;
          background-color: none;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .app {
    &-container {
      grid-template-columns: 64px 1fr auto;
    }
    &-icon {
      order: 1;
    }
    &-header {
      order: 2;
    }
    &-actions {
      order: 3;

      display: block;
    }
    &-buttons {
      align-self: right;
    }
    &-body {
      order: 4;

      grid-column-start: 2;
      grid-column-end: span 2;
    }
  }
}
