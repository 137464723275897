body {
  display: block;
}

.site-title {
  line-height: $base-line-height * $base-font-size * 2.25;
}

.tags-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.image-inline {
  @media screen and (min-width: $on-laptop) {
    max-width: 20vw;
  }
  &--center {
    display: block;
    margin: 0 auto;
  }
  &--left {
    float: left;
  }
  &--right {
    float: right;
  }
}

.apps {
  list-style: none;
  margin: 0;
  padding: 0;

  &-entry {
    margin: 1rem 0;
    &:last {
      margin-bottom: 0;
      &:last {
        margin-bottom: 0;
      }
      &__link {
        margin-bottom: 1rem;
        &-image {
          display: block;
          margin: 0 auto;
        }
      }
    }
    &:not(:last-child) {
      border-bottom: #eee 1px solid;
      margin-bottom: 2rem;
      padding-bottom: 2rem;
    }

    &__icon {
      text-align: center;
    }

    @media screen and (min-width: $on-laptop) {
      display: grid;
      grid-column-gap: 2rem;
      grid-template-columns: 128px auto;
      grid-template-rows: auto;

      &__icon {
        grid-column: 1;
        grid-row: 1 / span 2;
      }

      &__title {
        grid-column: 2;
        grid-row: 1;
      }

      &__description {
        grid-column: 2;
        grid-row: 2;
      }
    }
  }
}

div.highlight {
  border-radius: 3px;

  code {
    background-color: transparent;
  }
}

.social-media-list {
  margin: 0;
}

.post-list > li {
  &:not(:last-child) {
    border-bottom: #e8e8e8 1px solid;
  }

  & > h2 {
    margin: 0 0 15px 0;
    padding: 0;
  }
}

.post-content {
  p, img, iframe {
    margin-bottom: $spacing-unit / 2;
  }
  p img {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }
  p img:last-child {
    margin-bottom: 0;
  }
}

.flex-grid-thirds {
  display: flex;
  justify-content: space-between;
}
.flex-grid-thirds .col {
  width: 32%;
}

.pagination {
  padding: 15px;
  margin-bottom: 2rem;
  border: 1px #e8e8e8 solid;
  border-radius: 5px;

  p {
    margin: 0;
  }

  div:nth-child(2) {
    text-align: center;
  }
  div:last-child {
    text-align: right;
  }
}

.feed-subscribe {
  text-align: right;

  .svg-icon {
    padding: 5px;
  }
}

.button {
  display: inline-block !important;
  padding: 0.15em 0.5em !important;
  border-radius: 3px;

  &:hover {
    text-decoration: none !important;
  }
}

.is {
  &-dark {
    background-color: rgba($text-color,0.75) !important;
    color: #fff !important;

    &:hover {
      background-color: $text-color !important;
    }
  }
}

em {
  background-image: linear-gradient(
    to right,
    rgba(255, 225, 0, 0.2),
    rgba(255, 225, 0, 0.6) 4%,
    rgba(255, 225, 0, 0.4)
  );

  pre, code {
    background: rgba(238, 238, 255, 0.5);
  }
}

